// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // adminUrl: 'https://admin.growthdrivers.app/login',
  adminUrl: 'https://dev-admin.growthdrivers.app/login',
  //dev
  s3Bucker: 'https://growth-upload.s3.us-east-2.amazonaws.com/',
  // live
  // s3Bucker: 'https://growth-prod.s3.us-east-2.amazonaws.com/',
  backend: {
    // baseURL:"https://api.growthdrivers.app"
    baseURL:"https://dev-api.growthdrivers.app"
  }
};


 
/*
baseURL:"https://dev-api.growthdrivers.biz"
https://api.growthdrivers.biz   

 http://3.23.145.56:3000
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
