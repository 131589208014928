
<div class="wrapper login-section pt-52">
    <div class="loginHeader">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>
<!-- observation received section-->
<div class="observationReceived" *ngIf="errorCode==111 || errorCode==222|| errorCode==405">
    <h2>{{errorCode==111?'This observation has already received its maximum number of responses.':'The link for this observation has already expired.'}}</h2>
    <figure>
        <img src="../../../assets/images/warning.png" alt="warning image"/>
    </figure>
    <p>If you have any questions or need additional assistance, please contact our support team at <a href="mailto:support@guidewise.io">suppor&#64;guidewise.io</a>. </p>
    <ul class="socialMedia">
        <li><a href="https://www.facebook.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/facebook.png"> </a></li>
        <li><a href="https://www.instagram.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/instagram.png"> </a></li>
        <li><a href="https://www.linkedin.com/company/guidewisedotio" target="_blank"><img src="../../../assets/images/linkedin.png"> </a></li>
        <li><a href="https://twitter.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/twitter.png"> </a></li>
        <li><a href="https://www.youtube.com/@guidewisedotio" target="_blank"><img src="../../../assets/images/ic_youtube.png"> </a></li>
    </ul>
</div>
<div class="observationReceived mb-4" *ngIf="errorCode==406">
    <h2>This observation has already received its maximum number of responses.</h2>
    <figure>
        <img src="../../../assets/images/warning.png" alt="warning image"/>
    </figure>
    <p>If you have any questions or need additional assistance, please contact our support team at <a href="mailto:support@guidewise.io">support&#64;guidewise.io</a>. </p>
    <ul class="socialMedia"> 
        <li><a href="https://www.facebook.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/facebook.png"> </a></li>
        <li><a href="https://www.instagram.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/instagram.png"> </a></li>
        <li><a href="https://www.linkedin.com/company/guidewisedotio" target="_blank"><img src="../../../assets/images/linkedin.png"> </a></li>
        <li><a href="https://twitter.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/twitter.png"> </a></li>
        <li><a href="https://www.youtube.com/@guidewisedotio" target="_blank"><img src="../../../assets/images/ic_youtube.png"> </a></li>
    </ul>
</div>
<div class="observationReceived mb-4" *ngIf="errorCode==423">
    <h2>The link for this observation has already expired.</h2>
    <figure>
        <img src="../../../assets/images/warning.png" alt="warning image"/>
    </figure>
    <p>If you have any questions or need additional assistance, please contact our support team at <a href="mailto:support@guidewise.io">support&#64;guidewise.io</a>. </p>
    <ul class="socialMedia"> 
        <li><a href="https://www.facebook.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/facebook.png"> </a></li>
        <li><a href="https://www.instagram.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/instagram.png"> </a></li>
        <li><a href="https://www.linkedin.com/company/guidewisedotio" target="_blank"><img src="../../../assets/images/linkedin.png"> </a></li>
        <li><a href="https://twitter.com/guidewisedotio/" target="_blank"><img src="../../../assets/images/twitter.png"> </a></li>
        <li><a href="https://www.youtube.com/@guidewisedotio" target="_blank"><img src="../../../assets/images/ic_youtube.png"> </a></li>
    </ul>
</div>
<!-- observation password screen-->
<div class="observationPassword" *ngIf="errorCode!=111 && errorCode!=406 && errorCode!=405 && errorCode!=423 && errorCode!=222">
<h2>{{DataShared?.title}}
    <span class="createdBy" *ngIf="!DataShared?.is_creator_anonymous && DataShared?.user?.first_name">Created by: {{DataShared?.user?.first_name +' ' + DataShared?.user?.last_name}}</span>
</h2> 
<!-- step one -->
    <form [formGroup]="passwordForm" *ngIf="showPasswordSection && DataShared?.is_password_enable">
        <p>Welcome to the {{DataShared?.title}} Observation for {{DataShared?.subject_type == 'User'? DataShared?.subject?.first_name + ' '+ DataShared?.subject?.last_name: DataShared?.subject_type == 'Team' ? DataShared?.subject?.name: DataShared?.subject_type =='Organisation'? DataShared?.subject?.name :DataShared?.subject_type =='Other' ?DataShared?.subject_title : ''}}. To access the Observation form, please enter the password below. If you have any questions or need further assistance,
        please contact our support team at <a
            href="mailto:support@guidewise.io">support&#64;guidewise.io</a>.</p>
        <div class="observationPasswordForm" >
            <div class="form-group">
                <label class="customLabel">Please Enter Password</label>
                <div class="password">
                    <input [type]="passwordType" class="form-control" placeholder="Enter password..." formControlName="password" [ngClass]="{ 'is-invalid': (passwordForm.invalid && passwordForm.get('password')?.touched)} " (keydown.enter)="onEnterKey($event)"/> 
                    <!-- ,'is-invalid': passwordForm.valid  -->
                    <div class="invalid-feedback">
                        <div *ngIf="errorPassword==400 || showError"><span class="dangerImage"></span></div>
                            
                        <div class="text-danger" *ngIf="errorPassword==400 || showError">
                            The password you have entered is not correct. Please try again.
                        </div>
                       </div>
                    <div class="text-success" *ngIf="errorPassword==200" >
                        <!-- <span class="tickImage"></span>
                        The password you entered is correct. -->
                    </div>
                    <a class="showPass" (click)="togglePassword()">
                        <img [src]="passwordShown == false? '../../../assets/images/eye-off-2.svg' :'../../../assets/images/ic_eye_show_red.svg' " /></a>
                </div>
            </div>
        </div>
        <div class="buttonGroup">
            <button class="commonBtn clear_btn upperCase" (click)="clear(1)"  [class.disabled]="passwordForm.value.password == ''" [disabled]="passwordForm.value.password == ''">Clear </button>
            <button type="submit" class="commonBtn fill upperCase" [class.disabled]="!passwordForm.valid" (click)="VerifyPassword()" #nextButton >Next </button>
            <!--  <img src="../../../assets/images/right-long-arrow-white.png"/> -->
        </div>
    </form>
    <!-- step two -->
    <form [formGroup]="userDetailFrom" *ngIf="showUserDetailFrom">
        <p>Please enter your personal details below. If you have any questions or need further assistance, please contact our  support team at <a
            href="mailto:support@guidewise.io">support&#64;guidewise.io</a>.</p>
        <div class="userDetailFrom">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <!-- [class.disabled]="DataShared?.is_respondents_anonymous" -->
                        <label>First Name <span *ngIf="DataShared?.is_respondents_anonymous">(optional)</span></label>
                        <input class="form-control" placeholder="Enter first name..." formControlName="first_name"/>
                     </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Last Name <span *ngIf="DataShared?.is_respondents_anonymous">(optional)</span></label>
                        <input class="form-control" placeholder="Enter last name..." formControlName="last_name"/>
                       </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>Email Address <span *ngIf="DataShared?.is_respondents_anonymous">(optional)</span></label>
                        <input class="form-control" placeholder="Enter email address..." formControlName="email"/>
                        <div class="text-danger" *ngIf="userDetailFrom.controls['email'].hasError('pattern')">
                            *Please enter valid email address.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttonGroup">
            <a href="javascript:void(0);" class="commonBtn clear_btn upperCase" [class.disabled]="!userDetailFrom.valid  || checkOptionFiledRequired()" (click)="clear(2)">Clear </a>
            <button type="submit" class="commonBtn fill upperCase" [class.disabled]="!userDetailFrom.valid"
                (click)="VerifyDetails('moveNext')">Next </button>
                <!--  <img src="../../../assets/images/right-long-arrow-white.png" /> -->
        </div>
    </form>
    <!-- step three -->
    <form [formGroup]="passCodeForm" *ngIf="showPasscodeSection">
        <p>We just sent a one-time passcode to your inbox. Please enter this passcode below to verify your email address. If you
        have any questions or need further assistance, please contact our support team at  <a
                href="mailto:support@guidewise.io">support&#64;guidewise.io</a>.</p>
            <div class="observationPasswordForm">
                <div class="form-group">
                    <label class="customLabel">Please Enter One-Time Passcode</label>
                        <input type="text" formControlName="password" class="form-control" placeholder="Enter passcode..." [ngClass]="{ 'is-invalid': (passCodeForm.invalid && passCodeForm.get('password')?.touched),'is-valid': passCodeForm.valid }"/>  
                        <div class="invalid-feedback">
                            <div *ngIf="errorPasscode==400"><span class="dangerImage"></span></div>
                            <div class="text-danger" *ngIf="errorPasscode==400">
                                The passcode you entered is not correct. Please try again.
                            </div>
                        </div>
                        <div class="text-success" *ngIf="errorPasscode==200">
                            <!-- <span class="tickImage"></span>
                            The passcode you entered is correct. -->
                        </div>
                </div> 
                <p class="resendPasscode">
                    <span>Passcode sent to your email address: (<a class="semibold">{{userDetailFrom.value.email}}</a>)</span><br>
                    Didn't receive the passcode? <a
                        href="javascript:void(0)" (click)="VerifyDetails('resend')">Click here to resend</a>.</p>
            </div>
        <div class="buttonGroup">
            <!-- <img
                src="../../../assets/images/left-long-arrow-white.png" /> -->
            <a href="javascript:void(0);" class="commonBtn blue upperCase" (click)="backStep($event,0)"> PREVIOUS</a>
            <button class="commonBtn clear_btn upperCase" (click)="clear(3)" [class.disabled]="passCodeForm.value.password == ''" [disabled]="passCodeForm.value.password == ''">Clear </button>
            <button type="submit" class="commonBtn fill upperCase" [class.disabled]="!passCodeForm.valid"
                (click)="onSubmit()">Next </button>
                <!--  <img src="../../../assets/images/right-long-arrow-white.png" /> -->
        </div>
    </form>
</div>
<div class="footerWrap" >
    <div class="copyright">
        <a class="_logo_a mb-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
            <img class="_logo mt-1" src="../../../assets/images/guidewise_primary mark_final.jpg">
        </a>
        <span>Growthdrivers R3.0.0</span>
        <p>&copy; 2021 -
            <!-- {{date | date: 'y'}}  -->
            2025 Guidewise
        </p>
    </div>
</div>
</div>