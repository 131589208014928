<div *ngIf="showEmotion===0" class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader" *ngIf="reqId">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>


    <!--  -->
    <div *ngIf="!showThanks">
        <form [formGroup]="observationForm">
            <div class="wrapper new-entry" id="new-entry">
                <div class="new-entry-container">
                    <div class="entry-data-wrap no-header-top pb-0" *ngIf="reviewId">
                        <h4 class="" [class.large]="observationDetails?.subject_title.length > 80">
                            <a class="inlineBlock"><span class="cross-main" (click)="goToBack()">
                                    <img class="cross1" src="assets/images/close-icon-black.svg">
                                </span><span class="heading noHeader " (click)="goToBack()"
                                    style="cursor: pointer;">Feedback for
                                    <span class="capitalize" *ngIf="observationDetails?.subject_type=='User'">
                                        {{observationDetails?.subject?.first_name}}
                                        {{observationDetails?.subject?.last_name}}
                                    </span>
                                    <span class="capitalize" *ngIf="observationDetails?.subject_type=='Organisation'">
                                        {{(observationDetails?.observation_subject_title)}}
                                    </span>
                                    <span class="capitalize"
                                        *ngIf="observationDetails?.subject_type!='User' && observationDetails?.subject_type!='Organisation'">
                                        {{(observationDetails?.subject_type=='Other')?(observationDetails?.subject_title):(observationDetails?.subject?.name
                                        || observationDetails?.subject?.full_name)}}
                                    </span>
                                </span>
                                <span class="created-by grey date no-header"
                                    *ngIf="observationDetails?.is_creator_anonymous==false">
                                    Created by: {{observationDetails?.user?.first_name}}
                                    {{observationDetails?.user?.last_name}} | Revised:
                                    {{utcToLocal(observationDetails?.updated_at)}}
                                    | Version: {{observationDetails?.observation_version || '-'}}
                                </span>
                            </a>
                        </h4>
                    </div>
                    <div class="entry-data-wrap" [ngClass]="reviewId ? 'apply' : '' ">
                        <h4 class="mb-5 posParent" style="margin-top: -15px;" *ngIf="reqId">
                            <a><span class="cross-main" *ngIf="reqId">
                                    <img class="cross" src="">
                                </span><span class="heading">Feedback for
                                    <span class="capitalize" *ngIf="observationDetails?.subject_type=='User'">
                                        {{observationDetails?.subject?.first_name}}
                                        {{observationDetails?.subject?.last_name}}
                                    </span>
                                    <span class="capitalize" *ngIf="observationDetails?.subject_type!='User'">
                                        {{(observationDetails?.subject_type=='Other')?(observationDetails?.subject_title):(observationDetails?.subject?.name)}}
                                    </span>
                                    <br>
                                    <span class="created-by grey date pos">
                                        Created: {{utcToLocal(observationDetails?.created_at)}} | Revised:
                                        {{utcToLocal(observationDetails?.updated_at)}}
                                        | Version: {{observationDetails?.observation_version || '-'}}
                                    </span>
                                </span>
                                <span class="created-by grey date mt"
                                    *ngIf="observationDetails?.is_creator_anonymous==false">
                                    Created by: {{observationDetails?.user?.first_name}}
                                    {{observationDetails?.user?.last_name}}
                                    <!-- | Revised:
                                    {{utcToLocal(observationDetails?.updated_at)}}
                                    | Version: {{observationDetails?.observation_version || '-'}} -->
                                </span>

                            </a>
                        </h4>
                        <div class="row">
                            <div class="{{reviewId?'col-md-4':'col-md-6'}} col-6-set">
                                <div class="commonRow ">
                                    <h5 class="entry-label">Title</h5>
                                    <div class="form-group">
                                        <input formControlName="title" type="text"
                                            style="background-color: #cfcdcd !important;" placeholder="Enter title..."
                                            class="form-control txt_right_pad" id="title" maxlength="1000">
                                        <!-- <i class="mdi mdi-microphone des-micro"></i> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 col-6-set" *ngIf="reviewId">
                                <div class="commonRow ">
                                    <h5 class="entry-label">Category</h5>
                                </div>
                                <input formControlName="category" type="text"
                                    style="background-color: #cfcdcd !important;" placeholder="Enter title..."
                                    class="form-control txt_right_pad" id="title" maxlength="2000">
                            </div>

                            <div class="{{reviewId?'col-md-4':'col-md-6'}} col-12-set">
                                <div class="commonRow ">
                                    <h5 class="entry-label">Deadline</h5>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="date">
                                            <input disabled type="text" autocomplete="off"
                                                style="background-color: #cfcdcd !important;" [value]="pastDate"
                                                class="form-control disable" placeholder="No deadline selected"
                                                placement="bottom">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="commonRow ">
                                        <h5 class="entry-label">Description</h5>
                                        <!-- <span
                                            class="{{observationForm?.controls?.description?.value?.length== maxChars1 ? ('txtRed'):''}} charCount">{{observationForm?.controls?.description?.value?.length}}/{{maxChars1}}</span> -->
                                        <textarea formControlName="description" class="form-control" readonly disabled
                                            id="desc" maxlength="2000" placeholder="Describe the event…" rows="5"
                                            (keyup)="service.text = description"></textarea>
                                        <!-- <i class="mdi mdi-microphone des-micro"></i> -->
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!reviewId" class="col-md-12">
                                <div class="form-group mb-1">
                                    <div class="commonRow ">
                                        <h5 class="entry-label">Instructions</h5>
                                    </div>
                                    <p class="color_black" *ngIf="observationDetails?.is_creator_anonymous==false">
                                        Thank you for providing feedback for <span
                                            *ngIf="observationDetails?.subject_type=='User'">{{observationDetails?.subject?.first_name
                                            || ''}}
                                            {{observationDetails?.subject?.last_name || ''}}.</span>
                                        <span
                                            *ngIf="observationDetails?.subject_type!='User' && observationDetails?.subject_type!='Organisation'">{{(observationDetails?.subject_type=='Other')?(observationDetails?.subject_title):(observationDetails?.subject?.name
                                            || '')}}.</span>
                                        <span *ngIf="observationDetails?.subject_type=='Organisation'">
                                            {{(observationDetails?.observation_subject_title)}}.
                                        </span>
                                        To submit your feedback, please respond to the following prompts. Rest assured,
                                        your feedback for
                                        <span *ngIf="observationDetails?.subject_type=='User'">
                                            {{observationDetails?.subject?.first_name || ''}}
                                            {{observationDetails?.subject?.last_name || ''}}</span>
                                        <span *ngIf="observationDetails?.subject_type=='Team'">
                                            {{observationDetails?.subject?.name}}
                                        </span>
                                        <span
                                            *ngIf="observationDetails?.subject_type=='Organisation'">{{(observationDetails?.observation_subject_title)}}</span>
                                        is completely confidential. Your candid and constructive responses are
                                        appreciated. If you have any questions or need further assistance, please reach
                                        out to our support team at <a
                                            href="mailto:support@guidewise.io">support@guidewise.io</a>.
                                    </p>
                                    <p class="color_black" *ngIf="observationDetails?.is_creator_anonymous==true">
                                        Thank you for providing feedback for <span class="capitalize"
                                            *ngIf="observationDetails?.subject_type=='User'">
                                            {{observationDetails?.subject?.first_name}}
                                            {{observationDetails?.subject?.last_name}}
                                        </span>
                                        <span class="capitalize" *ngIf="observationDetails?.subject_type!='User'">
                                            {{(observationDetails?.subject_type=='Other')?(observationDetails?.subject_title):(observationDetails?.subject?.name)}}
                                        </span>.
                                        To submit your feedback, please respond to the following prompts. Rest assured,
                                        your feedback is completely anonymous and confidential. Your candid and
                                        constructive responses are appreciated. If you have any questions or need
                                        further assistance, please reach out to our support team at <a
                                            href="mailto:support@guidewise.io">support@guidewise.io</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="reviewId" class="subjectProfile" hidden>
                            <h5>Subject Profile</h5>
                            <div class="info-box">
                                <div class="profile_info">
                                    <h6>First Name:</h6>
                                    <p>{{observationDetails?.subject?.first_name}}</p>
                                </div>
                                <div class="profile_info">
                                    <h6>Last Name:</h6>
                                    <p>{{observationDetails?.subject?.last_name}}</p>
                                </div>
                                <div class="profile_info">
                                    <h6>Username:</h6>
                                    <p>{{observationDetails?.subject?.user_name}}</p>
                                </div>
                                <div class="profile_info">
                                    <h6>Work Email:</h6>
                                    <p class="smallLetter">{{observationDetails?.subject?.email}}</p>
                                </div>
                                <div class="profile_info">
                                    <h6>Job Function:</h6>
                                    <p>{{observationDetails?.subject?.job_function}}</p>
                                </div>
                                <div class="profile_info">
                                    <h6>Job Title:</h6>
                                    <p>{{observationDetails?.subject?.job_title}}</p>
                                </div>
                                <div class="profile_info">
                                    <h6>Total Points:</h6>
                                    <p>{{observationDetails?.subject?.points}}</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="reviewId" class="bottom_cal rankingWrap">
                            <div class="info common-width">
                                <h5>
                                    Total Respondents
                                </h5>
                                <span class="boldText">
                                    {{observationDetails?.observation_respondents?.length || 'N/A'}}
                                </span>
                            </div>
                            <div class="info common-width">
                                <h5>
                                    Total Emotions Experienced
                                </h5>
                                <span class="boldText">
                                    {{observationDetails?.emotion_experienced_count}}
                                </span>
                            </div>
                            <div class="info common-width">
                                <h5>
                                    Avg. Intensity Experienced
                                </h5>
                                <span class="boldText">
                                    {{observationDetails?.avg_intensity_reported | number : '1.1-2'}}/10.0
                                </span>
                            </div>
                            <div class="info common-width">
                                <h5> Top 5 Emotion Columns Selected
                                </h5>
                                <div *ngFor="let item of observationDetails?.top_emotional_columns_selected"
                                    class="row square table_form" [ngStyle]="{'background':item?.color_code}">
                                    <div class="col-8 field">
                                        {{item?.title}}
                                    </div>
                                    <div class="col-4 value">{{item?.count}}</div>
                                </div>
                            </div>
                            <div class="info common-width">
                                <h5>
                                    Top 5 Emotions Experienced
                                </h5>
                                <div *ngFor="let item of observationDetails?.top_five_emotions_experienced;let i =index;"
                                    class="row table_form" [class.round]="i==0">
                                    <div class="col-8 field">
                                        {{item?.name}}
                                    </div>
                                    <div class="col-4 value">{{item?.count| number : '1.0-0'}}</div>
                                </div>
                            </div>
                            <div class="info common-width">
                                <h5>
                                    Top 5 Avg. Intensities Experienced
                                </h5>
                                <div *ngFor="let item of observationDetails?.avg_intensities_per_emotion;let i = index;"
                                    class="row table_form" [class.round]="i==0">
                                    <div class="col-8 field">
                                        {{item?.name}}
                                    </div>
                                    <div class="col-4 value">{{item?.avg | number : '1.1-1'}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- word cloud image -->
                        <div class="cloudImage" *ngIf="reviewId">
                            <figure *ngIf="observationDetails?.word_cloud != ''">
                                <img src="{{globalUrl + observationDetails?.word_cloud}}" alt="word cloud" />
                            </figure>
                        </div>
                        <!-- Graph and Table -->
                        <div class="report1" *ngIf="reviewId">
                            <div class="chartwrap" id="report1">
                                <div echarts [options]="reportOneChart" class="demo-chart"></div>
                            </div>
                            <!-- need to hide this -->
                            <div class="paddingLR20">
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <tbody>
                                            <tr class="recent-link" *ngFor="let alldata of tableData;">
                                                <td *ngFor="let data of alldata;let i = index">
                                                    <span style="text-align: center;" *ngIf="data.parent==1"
                                                        [ngStyle]="{'background-color':data.color_code}">{{data.name ?
                                                        (data.name):''}}</span>
                                                    <span style="text-align: center;" *ngIf="data.parent==0">{{data.name
                                                        ? (data.name):''}}
                                                        ({{data.count ? (data.count):'0'}})</span>
                                                </td>
                                                <span class="hovercard">
                                                    <div class="tooltiptext">
                                                        <div *ngIf="alldata[0]?.parent==1" hidden>
                                                            {{alldata[0]?.name}}
                                                        </div>
                                                        <ul class="tooltip_list">
                                                            <li class="list" hidden>
                                                                Emotions Experienced: {{alldata?.total}}
                                                            </li>
                                                            <li *ngIf="alldata[1]?.count > 0" hidden>
                                                                <ul>
                                                                    <li *ngIf="alldata[1]?.count > 0"> 1st:
                                                                        {{alldata[1]?.name}}
                                                                        ({{alldata[1]?.count ?
                                                                        (alldata[1]?.count):'0'}})</li>
                                                                    <li *ngIf="alldata[2]?.count > 0"> 2nd:
                                                                        {{alldata[2]?.name}}
                                                                        ({{alldata[2]?.count ?
                                                                        (alldata[2]?.count):'0'}})</li>
                                                                    <li *ngIf="alldata[3]?.count > 0"> 3rd:
                                                                        {{alldata[3]?.name}}
                                                                        ({{alldata[3]?.count ?
                                                                        (alldata[3]?.count):'0'}})</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </span>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- need to hide this -->
                        <div *ngIf="reqId" class="w-100">
                            <div formArrayName="comments"
                                *ngFor="let item of observationForm.get('comments')['controls']; let i = index;"
                                class="col-md-12 p-0">
                                <div class="form-group" [formGroupName]="i" >
                                    <div class="commonRow ">
                                        <h5 class="entry-label question_label" [id]="'comment'+ i" #comment+{{i}}>{{i + 1}})
                                            {{observationDetails?.form[i]?.title}}</h5>
                                    </div>
                                    <div class="commonRow feedbackrow d-flex">
                                        <div class="col-6">
                                            <h5 class="entry-label">Emotions Experienced
                                                ({{getLength(pastEmotions[i].array)}})</h5>
                                            <div class="coverLayers">
                                                <div *ngIf="getLength(pastEmotions[i].array)>0"
                                                    class="box past custom-padding scroll_custom padding_b">
                                                    <ng-container *ngFor="let data of pastEmotions[i].array">
                                                        <div *ngIf="data.checked" class="tags">
                                                            <span class="tagBg"
                                                                [ngStyle]="{'background':data.color_code, 'opacity': opacityValue(data.intensity)}"></span>
                                                            <span class="float-left">{{data.name}}
                                                                ({{data.intensity | number : '1.1-2'}})</span>
                                                            <span class="float-right close-icon">
                                                                <!-- {{data.day}}D {{data.hours}}H
                                                                                                {{data.minutes}}M -->
                                                                <a style="margin: 3px 10px;" class="close-icon-black"
                                                                    (click)="data.checked = !data.checked; removeEmotion(i)">
                                                                    <img style="cursor: pointer;"
                                                                        src="assets/images/close-icon-black.svg"
                                                                        class="mCS_img_loaded">
                                                                </a>
                                                            </span>
                                                            <div class="clear"></div>
                                                        </div>
                                                    </ng-container>

                                                </div>

                                                <p *ngIf="getLength(pastEmotions[i].array)==0"><textarea disabled
                                                        #pastBelief placeholder="Select emotions..."
                                                        class="gd-textarea form-control disabled"></textarea></p>
                                                <a class="add_emotions clickable Position_css"
                                                    (click)="addEmotion(i+1,observationDetails?.form[i]?.title)">Select
                                                    Emotions</a>
                                                <!-- <a class="add_emotions clickable Position_css" [class.add-emotion-button]="pastEmotionError">Add
                                                                                Emotions</a> -->
                                            </div>
                                            <!-- <span *ngIf="pastEmotionError" class="warnings">{{pastEmotionErrorMsg}}</span> -->
                                        </div>
                                        <div class="col-6">
                                            <h5 class="entry-label">Response </h5>
                                            <!-- <span
                                                                                class="{{observationForm?.controls['comment'+(i+1)]?.value?.length == 2000 ? ('txtRed'):''}} charCount">
                                                                                {{observationForm?.controls['comment'+(i+1)]?.value?.length ?
                                                                                observationForm?.controls['comment'+(i+1)]?.value?.length :
                                                                                0}}/{{'2000'}}</span> -->
                                            <span
                                                class="{{observationForm.value.comments[i]?.name?.length == 2000 ? ('txtRed'):''}} charCount">
                                                {{observationForm.value.comments[i]?.name?.length ?
                                                observationForm.value.comments[i]?.name?.length :
                                                0}}/{{'2000'}}</span>
                                            <div class="coverLayers">
                                                <!-- <p><textarea #pastBelief maxlength="2000"
                                                                                        [formControlName]="'comment' + (i+1)"
                                                                                        placeholder="Enter notes...."
                                                                                        class="gd-textarea form-control"></textarea></p> -->
                                                <p><textarea #pastBelief maxlength="2000"
                                                        placeholder="Enter response..." (keyup)="keyvalue($event,i)"
                                                        formControlName="name" (change)="completeProgress(i)"
                                                        class="gd-textarea form-control"></textarea></p>
                                                <i class="mdi mdi-microphone des-micro"
                                                    (click)="openSpeechModal(speech,i+1)"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-100" *ngIf="reviewId && checkGotResponses()">
                            <!-- <div class="chartwrap" id="report1">
                                    <div echarts [options]="reportOneChart" class="demo-chart"></div>
                                </div> -->
                            <div class="border_bottom"
                                *ngFor="let items of respondentsResponses | keyvalue;let index = index;">
                                <!-- <h5 class="fhead" *ngIf="items?.observation_response?.length!=0">
                                        Submission {{index + 1}}:
                                    </h5> -->
                                <div class="headingSection">
                                    <h5 class="fhead ml-0 mt-0">
                                        Submission {{index + 1}}:
                                    </h5> 
                                </div>
                                <div>
                                    <div class="row">
                                        <div *ngFor="let data of items.value?.observation_responses | slice:loadStart:loadDataEndObj[items.key];let i = index;"
                                            class="col-md-12" id="moreData">
                                            <div class="form-group">
                                                <div class="commonRow ">
                                                    <h5 class="entry-label question_label ">{{i + 1}}&#x29;
                                                        {{replaceWithName(data?.observation_form)}}</h5>
                                                </div>
                                                <div class="commonRow feedbackrow d-flex">
                                                    <div class="col-6">
                                                        <h5 class="entry-label">Emotions Experienced
                                                            ({{data?.observation_response_emotions?.length}})</h5>
                                                        <div class="coverLayers">
                                                            <div *ngIf="data?.observation_response_emotions?.length>0"
                                                                class="box past custom-padding scroll_custom">
                                                                <ng-container
                                                                    *ngFor="let datas of data?.observation_response_emotions">
                                                                    <div class="tags">
                                                                        <span class="tagBg"
                                                                            [ngStyle]="{'background':datas?.emotion?.color_code, 'opacity': opacityValue(datas?.intensity)}"></span>
                                                                        <span
                                                                            class="float-left">{{datas?.emotion?.name}}
                                                                            ({{datas?.intensity | number :
                                                                            '1.1-1'}})</span>                                             <div class="clear"></div>
                                                                    </div>
                                                                </ng-container>

                                                            </div> 
                                                         </div> 
                                                    </div>
                                                    <div class="col-6">
                                                        <h5 class="entry-label">Response <span class="detect">(Detected:
                                                                {{data?.detected_emotion || 'Neutral'}})
                                                            </span></h5>
                                                        <span
                                                            class="{{data?.description?.length == 2000 ? ('txtRed'):''}} charCount">
                                                            {{data?.description?.length ? data?.description?.length :
                                                            0}}/{{'2000'}}</span>
                                                        <div class="coverLayers">
                                                            <p><textarea #pastBelief maxlength="2000" disabled
                                                                    [value]="data?.description"
                                                                    placeholder="Enter response..."
                                                                    class="gd-textarea form-control"></textarea></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="loadMoreData">
                                        <button
                                            *ngIf="loadDataEndObj[items.key] < items.value?.observation_responses.length"
                                            (click)="loadMore(items.key)" id="load-more" class="commonBtn fill">Load
                                            more</button>
                                        <div class="countOfPagination"
                                            *ngIf="loadDataEndObj[items.key] <= items.value?.observation_responses.length">
                                            Showing {{loadDataEndObj[items.key] + ' of ' +
                                            items.value?.observation_responses.length}} responses..</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                        <div *ngIf="reqId" class="footer">
                            <!-- <button type="button"
                                [class.disable]="
                                !observationForm.controls.comment1.value && !observationForm.controls.comment2.value && !observationForm.controls.comment3.value && !observationForm.controls.comment4.value && !observationForm.controls.comment5.value &&
                                pastEmotions1?.length == 0 && pastEmotions2?.length == 0 && pastEmotions3?.length == 0 && pastEmotions4?.length == 0 && pastEmotions5?.length == 0 "
                                [disabled]="
                                !observationForm.controls.comment1.value && !observationForm.controls.comment2.value && !observationForm.controls.comment3.value && !observationForm.controls.comment4.value && !observationForm.controls.comment5.value &&
                                pastEmotions1?.length == 0 && pastEmotions2?.length == 0 && pastEmotions3?.length == 0 && pastEmotions4?.length == 0 && pastEmotions5?.length == 0 "
                                class="commonBtn clear_btn" (click)="clearForm()"> clear
                            </button> -->
                            <button type="button" class="commonBtn clear_btn" (click)="clearForm()"
                                [class.disable]="!activateClearButton()"
                                [disabled]="!activateClearButton()"> clear
                            </button>
                            <!-- <button type="button" 
                            [class.disable]="observationForm?.invalid || pastEmotions1?.length == 0 || pastEmotions2?.length == 0|| pastEmotions3?.length == 0 || pastEmotions4?.length == 0 || pastEmotions5?.length == 0"
                            [disabled]="observationForm?.invalid || pastEmotions1?.length == 0 || pastEmotions2?.length == 0|| pastEmotions3?.length == 0 || pastEmotions4?.length == 0 || pastEmotions5?.length == 0"
                            class="commonBtn clear_btn" (click)="clearForm()"> clear</button> -->
                            <button type="button" [class.disable]="!activateSubmitButton()"
                                [disabled]="!activateSubmitButton()" class="commonBtn fill"
                                (click)="submit()">Submit</button>
                        </div>
                        <div *ngIf="reviewId" class="footer">
                            <button type="button" class="commonBtn clear_btn" (click)="back()"> Done</button>
                            <button type="button" class="commonBtn fill" (click)="downloadAsPDF()">Download</button>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="pd-tb" *ngIf="showThanks">
        <div class="container">
            <div class="linksent">
                <!-- <h2 class="heading2 mb-3">Thank you for submitting your feedback <span *ngIf="observationDetails?.is_creator_anonymous==false">for </span> <span *ngIf="observationDetails?.is_creator_anonymous==false"
                        class="capital">{{(observationDetails?.subject_type=='User')?(observationDetails?.subject?.first_name):(observationDetails?.subject_type=='Organisation')?
                        (observationDetails?.observation_subject_title):
                        (observationDetails?.subject_type=='Other')?(observationDetails?.subject_title):(observationDetails?.subject?.name)}}
                        {{(observationDetails?.subject_type=='User')?(observationDetails?.subject?.last_name):''}}.</span>
                </h2> -->
                <h2 class="heading2 mb-3">Thank you for submitting your response.</h2>
                <p class="greyText">If you have any questions or need further assistance, please reach out to our
                    support team at
                    <a class="hyperlink" href="mailto:support@guidewise.io" target="_blank">support@guidewise.io</a>.
                </p>
                <div class="social_media">
                    <a href="https://www.facebook.com/guidewisedotio/" title="Facebook" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/facebook (1).png">
                    </a>
                    <a href="https://www.instagram.com/guidewisedotio/" title="Instagram" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/instagram.png">
                    </a>
                    <a href="https://www.linkedin.com/company/guidewisedotio" title="LinkedIn" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/linkedin.png">
                    </a>
                    <a href="https://twitter.com/guidewisedotio/" title="Twitter" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/twitter.png">
                    </a>
                    <a href="https://www.youtube.com/@guidewisedotio" title="Youtube" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/ic_youtube.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="footerWrap" *ngIf="reqId">
        <div class="copyright">
            <a class="_logo_a mb-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo mt-1" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R3.0.0</span>
            <p>&copy; 2021 -
                <!-- {{date | date: 'y'}}  -->
                2025 Guidewise
            </p>
        </div>
    </div>
</div>
<ng-container *ngIf="showEmotion===1 ">
    <app-emotion [existingEmotionData]="myCommonArrEmotions" [existingTitle]="myexistingTitle" [notPas]="'true'"
        (emotionData)="handleEmotionData($event) "></app-emotion>
</ng-container>

<ng-container *ngIf="showEmotion===2 ">
    <app-emotion-intensity [myCommonArrEmotions]="myCommonArrEmotions " [existingTitle]="myexistingTitle"
        [notPas]="'true'" [emotionType]="1 " [emotionDataForIntensity]="emotionDataForIntensity "
        (emotionValues)="handleEmotionDataValues($event) ">
    </app-emotion-intensity>
</ng-container>
<ng-template #speech>
    <div class="modal-header text-center">
        <a class="pointer modal_close_icon" (click)="closeSpeech()"><span><img
                    src="assets/images/close-icon-black.svg"></span>
        </a>
        <h5 class="modal-title"> Click Microphone Button to Begin Voice Capture </h5>
        <h6 class="modal-title modal_title_2"> When finished speaking, click on the microphone button again to finish
            voice capture. </h6>
    </div>
    <!-- Modal body -->
    <div [class.modal_val_pad]="service?.text" class="modal-body modalpoppu modal_content" style="text-align: center;">
        {{service.text}}
    </div>
    <i *ngIf="showMicro" class="mdi mdi-microphone des-micro des-micro-modal" (click)="startService(speechType)"></i>
    <i *ngIf="!showMicro" class="mdi mdi-microphone des-micro des-micro-modal" (click)="stopService()"></i>
    <div *ngIf="!showMicro" class="ripple"></div>
    <div class="footerwrap_modal">
        <button [class.disable]="enableBtn" [disabled]="enableBtn" class="commonBtn button_padding modal_btn clear_btn"
            (click)="service.text = '';enableBtn = true;"> CLEAR </button>
        <button [class.disable]="enableBtn" [disabled]="enableBtn"
            class="commonBtn button_padding modal_btn modal_continue" (click)="complete(speechType)"> COMPLETE </button>
    </div>
</ng-template>


<ng-container *ngIf="showPdf == 'true'">
    <app-pdf-down-load *ngIf="observationDetails" [showPdfData]="observationDetails"></app-pdf-down-load>
</ng-container>