import { HttpErrorResponse, HttpParams,HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef,ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl, FormArray  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { VoiceRecognitionServiceService } from 'src/app/services/voice-recognition-service.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import html2canvas from 'html2canvas';
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
import { BsModalService } from 'ngx-bootstrap/modal';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DatePipe, Location } from '@angular/common';
import { EChartOption } from 'echarts';
@Component({
  selector: 'app-user-observations',
  templateUrl: './user-observations.component.html',
  styleUrls: ['./user-observations.component.scss']
})
export class UserObservationsComponent implements OnInit {
  title;
  backTodetail: any;
  baseUrl: string = environment.backend.baseURL;
  globalUrl: string = environment.s3Bucker;
  dropdownVal;
  pastDate = '';
  maxDate = new Date();
  description;
  maxChars = 1000;
  notValid=false
  maxChars1 = 600;
  searchGoalTxt = '';
  showMicro: boolean = true;
  isEdit = false;
  observationDetails;
  reqId;
  observationForm:FormGroup;
  showEmotion = 0;
  myCommonArrEmotions = [];
  bsConfig = { dateInputFormat: 'MMMM DD, YYYY', 'customTodayClass': 'today', showWeekNumbers: false, }
  pastEmotions=[]
  pastEmotions1 = [];
  pastEmotions2 = [];
  pastEmotions3 = [];
  pastEmotions4 = [];
  pastEmotions5 = [];
  index;
  emotionDataForIntensity = [];
  reviewId;
  speechType: string = '';
  modalRef;
  enableBtn = false;
  showPdf = 'false';
  showLoader = false;
  showThanks = false;
  reportOneChart: EChartOption;
  BarChartReport: any = [];
  graphData: any = [];
  tableData: any = [];
  imgUrlData: any;
  activeTab:any;
  currentPage:any;
  // password
  myexistingTitle:any;
  passwordForm: FormGroup = null;
  userDetailFrom: FormGroup ;
  passwordType: string = 'password';
  passwordShown: boolean = false;
  passwordWarningMsg: string;
  commonMsg: string;
  submitted = false;
  showPasswordSection = true;
  showUserDetailFrom = false;
  showPasscodeSection = false;
  comments:FormArray;
  date = new Date();
  beliefs_emotion;

  submissionUser;
  respondentsResponses : any = {};
  
loadStart= 0;
loadDataSize = 10;
loadDataEndObj: any = {};
formattedDate;

  scrollPosition: number = 0; 
  constructor(private ref:ChangeDetectorRef,
    public service: VoiceRecognitionServiceService,
    private admin: AdminService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private modalService1: BsModalService,
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
    private datePipe: DatePipe
  ) { }


  scrool(id) {
    let el = document.getElementById(id);
    // el?.scrollIntoView({ behavior: 'smooth',behavior: 'instant' block: 'start' });
    el?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    var headerOffset = 200;
    var elementPosition = el?.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  } 

  ngOnInit(): void {
    // const currentDate = new Date();
    // this.formattedDate = this.datePipe.transform(currentDate, 'full');
    // console.log(this.formattedDate,'formattedDate');
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.formattedDate =userTimezone;
    if (this.route.snapshot.queryParams.detail) {
      console.log('de');
      
      this.backTodetail = this.route.snapshot.queryParams.detail;
      this.route.queryParams.subscribe(params => {
        if (params['tab'])
          this.activeTab = Number(params['tab']);
         this.currentPage = Number(params['page']) || 1
      });
    }
    if (this.route.snapshot.queryParams.id) {
      console.log('rev');

      this.reviewId = this.route.snapshot.queryParams.id;
    } else {
      this.reqId = this.route.snapshot.params.id;
    }
    if (this.reqId) {
      this.getDeatilByToken();
    } else if (this.reviewId) {
      this.getDeatil();
      this.getGraphDetail();
    }
    this.observationForm = new FormGroup({
      title: new FormControl({ value: '', disabled: true }, Validators.required),
      category: new FormControl({ value: '', disabled: true }, Validators.required),
      description: new FormControl({ value: '', disabled: true }, Validators.required),
      comments: new FormArray([]),
      // comment1: ['', Validators.required],
      // comment2: ['', Validators.required],
      // comment3: ['', Validators.required],
      // comment4: ['', Validators.required],
      // comment5: ['', Validators.required],
    });
    this.passwordForm = new FormGroup({
      password: new FormControl('', Validators.required),
    })
    this.userDetailFrom = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
    })
  }

  // passowrd
   get f(): { [key: string]: AbstractControl } {
    return this.passwordForm.controls;
  }
  public togglePassword() {
    if (this.passwordShown) {
      this.passwordShown = false;
      this.passwordType = 'password';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';

    }
  }
onSubmit(){
  this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }
}
backStep(event,number){
  if(number == 1){
    this.showPasswordSection =true
    this.showUserDetailFrom =false
    this.showPasscodeSection =false
  }
}
next(event,number){
if(number == 1){
  this.showPasswordSection =false
  this.showUserDetailFrom =true
  this.showPasscodeSection =false
} else if(number == 2){ 
  this.showPasswordSection =false
  this.showUserDetailFrom =false
  this.showPasscodeSection =true
}
}
  getDeatil() {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    this.admin.getData(`${this.baseUrl}/api/v2/observations/` + this.reviewId, params)
      .subscribe(async (res) => {
        this.observationDetails = res['data']
        
        this.observationForm.patchValue(res.data);
        for (let i = 0; i < this.observationDetails?.form?.length; i++) {
          this.pastEmotions.push({array:[]})
          this.addItem()
          }
        if (this.observationDetails.deadline) {
          let eventDate = moment.utc(this.observationDetails.deadline).local().format('MMMM DD, YYYY');
          this.pastDate = eventDate;
        }
        console.log(this.observationDetails,'179')
        let userId=this.observationDetails?.observation_respondents[0].id
        this.observationDetails?.observation_respondents.forEach(respondent => {
          if (respondent.is_submitted){
            this.getSubmissionsDetail(respondent.id)
          }
        })
      })
  }
  selectUserID(event){ 
    let userId=event.target.value;
    if(userId){
      this.getSubmissionsDetail(userId)
    }
  }
  getSubmissionsDetail(userId) {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    this.admin.getData(`${this.baseUrl}/api/v3/submissions/` + userId, params)
      .subscribe(async (res) => {
        this.respondentsResponses[userId] = res
        this.loadDataEndObj[userId] = res.observation_responses.length >= this.loadDataSize ? this.loadDataSize : res.observation_responses.length
        console.log(this.respondentsResponses, 'this.respondentsResponses');
        
      })
  }

  checkGotResponses() {
    return Object.keys(this.respondentsResponses).length > 0;
  }
// loadMore(i){
//   this.pastEmotions[i].array.push(this.pastEmotions[i].array.length)
//   console.log(this.pastEmotions[i].array.push(this.pastEmotions[i].array.length),'this.pastEmotions[i].array.push(this.pastEmotions[i].array.length)');
  
    
//   if (this.loadDataEnd + 10 <= this.submissionUser?.observation_responses.length) {
//   this.loadDataEnd+=10;
// } else {
//   this.loadDataEnd = this.loadDataEnd + (this.submissionUser?.observation_responses.length - this.loadDataEnd)
// }
// }
loadMore(i) {
  
  if (this.loadDataEndObj[i]) {
    if (this.loadDataEndObj[i] + this.loadDataSize <= this.respondentsResponses[i].observation_responses.length) {
      this.loadDataEndObj[i] = this.loadDataEndObj[i] + this.loadDataSize;
    } else {
      this.loadDataEndObj[i] = this.loadDataEndObj[i] + (this.respondentsResponses[i].observation_responses.length - this.loadDataEndObj[i])
    }
  } else {
    if (this.loadDataSize <= this.respondentsResponses[i].observation_responses.length) {
      this.loadDataEndObj[i] = this.loadDataSize;
    } else {
      this.loadDataEndObj[i] = this.respondentsResponses[i].observation_responses.length
    }
  }
}
  getDeatilByToken() {
    this.admin.getDatas(`${this.baseUrl}/api/v2/observation_respondents/` + this.reqId)
      .subscribe(async (res) => {
        this.observationDetails = res['data']
        for (let i = 0; i < this.observationDetails?.form.length; i++) {
          this.pastEmotions.push({array:[]})
          this.addItem()
          }
        this.observationForm.patchValue(res.data);
        this.observationForm.controls.category.patchValue(res.data.category);
        this.observationDetails.form.filter(question => {
          question.title = question.title.replace('{{user_name}}', this.titleCaseWord(this.observationDetails?.subject?.first_name) + ' ' + this.titleCaseWord(this.observationDetails?.subject?.last_name));
        })
        if (this.observationDetails.deadline) {
          let eventDate = moment.utc(this.observationDetails.deadline).local().format('MMMM DD, YYYY');
          this.pastDate = eventDate;
        }
        
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.admin.errorAlert1('', error.error.message);
      })
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  handleEmotionData(data: any) {
    if (data[0] == -1) {
      this.showEmotion = 0;
      if (this.index > 0) {
        setTimeout(() => {
          this.scrool('comment' + (this.index - 1))
        }, 500);
      }
    } else if (data[0] == -2) {
      this.showEmotion = 1;
    } else {
      this.showEmotion = data[0];
      this.emotionDataForIntensity = data[1];
    }
  }

  addEmotion(i,title) {
    console.log(i,'emotion id',title);
    // this.scrollPosition = window.scrollY;
    this.index = i;
    this.myexistingTitle=title
      this.myCommonArrEmotions = this.pastEmotions[i-1].array;
    this.showEmotion = 1;
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
});
  }

  handleEmotionDataValues(data: any) {
    if (data[0] == -1) {
      this.showEmotion = 0;
    } else if (data[0] == -2) {
      this.myCommonArrEmotions = data[1];
      this.showEmotion = 1;
    } else {
      this.showEmotion = data[0];
      this.pastEmotions[this.index-1].array = data[1];
      this.pastEmotions[this.index-1].array.forEach((e) => {
          e['checked'] = true;
        })
        
    this.notValid=false
        this.checkpastEmotions()
      console.log(this.pastEmotions[this.index-1].array,this.index-1)
      this.activateSubmitButton()
      this.ref.detectChanges()
      this.myCommonArrEmotions = data[1];

      if (this.index > 0) {
        setTimeout(() => {
          this.scrool('comment' + (this.index - 1))
        }, 500);
      }
    }
  }

  removeEmotion(i) {
    this.pastEmotions[i].array = this.pastEmotions[i].array.filter((e) => { return e.checked == true });
    this.myCommonArrEmotions = this.pastEmotions[i].array;
    this.notValid=false
    this.checkpastEmotions()
  }
  opacityValue(value) {
    let number = parseInt(value);
    if (number > 9) {
      return "1";
    }
    else if (number < 1) {
      return "0.1";
    } else {
      return "0." + number;
    }
  }
  getLength(array) {
      return array?.length || 0;
  }

  submit() {
    this.admin.showLoader = true;
    const data = {
      response: this.prepareddataforapi()
    }
    // console.log(data)
    this.admin.patchData(this.baseUrl + '/api/v2/observation_respondents/' + this.reqId, data).subscribe(res => {
      this.showThanks = true;
      this.admin.showLoader = false;
      this.admin.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">Successfully submitted</span>', '');
      this.clearForm();
    })
  }

  clearForm() {
    this.observationForm.reset();
    this.observationForm.patchValue(this.observationDetails);
    this.observationForm.controls.category.patchValue(this.observationDetails.category);
    this.pastEmotions=[];
    for (let i = 0; i < this.observationDetails?.form.length; i++) {
      this.pastEmotions.push({array:[]})
      this.observationForm.value.comments[i].name=''
      }
  }

  prepareddataforapi() {
    let data;
    let dataTobeSend = [];
    this.observationDetails.form.filter((question, i) => {
      let observation_response_emotions;
      let observation_response_emotions_attributes = [];
      console.log(this.pastEmotions[i])
      this.pastEmotions[i].array.filter(item => {
            observation_response_emotions = {
              emotion_id: item.emotion_id,
              intensity: item?.intensity
            }
            observation_response_emotions_attributes.push(observation_response_emotions);
          })
      data = {
        observation_form_id: question?.id,
        description: this.observationForm?.value?.comments[i]?.name,
        observation_response_emotions_attributes: observation_response_emotions_attributes,
        detected_emotion: this.observationForm?.value?.comments[i]?.emotions_detected
      }
      dataTobeSend.push(data);
    })
    return dataTobeSend;
  }

  replaceWithName(name) {
    return name.replace('{{user_name}}', this.titleCaseWord(this.observationDetails?.subject?.first_name) + ' ' + this.titleCaseWord(this.observationDetails?.subject?.last_name));
  }

  public downloadAsPDF() {
    window.open(`${this.baseUrl}/api/v3/pdf/download.pdf?id=` + this.reviewId + '&access_token=' + localStorage.getItem('access_token') + '&time_zone='+this.formattedDate, "_blank");
    // let params = new HttpParams();
    // params = params.append('id', this.reviewId);
    // params = params.append('access_token', localStorage.getItem('access_token'));
    // this.admin.getData(`${this.baseUrl}/api/v1/test/download`, params)
    //   .subscribe(async (res) => {
   
    //   })

  }

  public getUrl() {
    let data = {
      id: this.reviewId,
      access_token: localStorage.getItem('access_token'),
      image: this.imgUrlData
    }
    this.admin.postData(`${this.baseUrl}/api/v3/pdf/upload_graph`, data)
      .subscribe(async (res) => {

      })
  }


  openSpeechModal(template: TemplateRef<any>, type) {
    this.service.stop();
    this.service.text = '';
    this.enableBtn = true;
    if (this.service.init()) {
      this.modalRef = this.modalService1.show(template, {
        class: 'modal-dialog w-6 speech_modal',
        backdrop: 'static',
      });
      this.speechType = type;
    }
  }

  complete(type) {
    this.modalRef.hide();
    // this.observationForm?.controls['comment' + (type)].patchValue(this.service.text.substring(0, 1000));
    console.log(this.service.text.substring(0, 2000))
    // this.observationForm.value.comments[type-1].name = this.service.text.substring(0, 1000);
    let taskListArrays = this.observationForm.get('comments') as FormArray;
        
taskListArrays.controls[type-1].patchValue({"name":this.service.text.substring(0, 2000)})
   this.completeProgress(type-1)
 
    // this.comments.at(type-1).patchValue(this.service.text.substring(0, 1000));
    console.log(this.observationForm.value)
    this.ref.detectChanges();
    this.service.text = '';
    let audio = new Audio();
    audio.src = "assets/audio/music_marimba_confirm.mp3";
    audio.load();
    audio.play();
  }

  closeSpeech() {
    this.modalRef.hide();
    this.service.stop();
    this.showMicro = true;
    this.service.text = '';
  }


  startService(type) {
    this.showMicro = false;
    this.service.text = this.observationForm?.value.comments[type-1].name;
    this.service.start();
  }

  stopService() {
    this.enableBtn = false;
    this.service.stop();
    this.showMicro = true;
  }

  back() {
    this.router.navigate(['observations/' + this.reviewId]);
  }
  goToBack() {
    if (this.backTodetail) {
      // this.router.navigate(['observations/' + this.reviewId]);
      this.router.navigate(['/observations/' +this.reviewId], { queryParams: { 'tab': this.activeTab, 'page': this.currentPage } })
    } else {
      if (this.observationDetails.form_status == 0) {
        this.router.navigate(['/observations'], { queryParams: { 'tab': this.observationDetails.form_status } })
      } else if (this.observationDetails.form_status == 1) {
        this.router.navigate(['/observations'], { queryParams: { 'tab': this.observationDetails.form_status } })
      } else if (this.observationDetails.form_status == 2) {
        this.router.navigate(['/observations'], { queryParams: { 'tab': this.observationDetails.form_status } })
      }
    }
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }

  showChart(data) {
    this.reportOneChart = {
      responsive: true,
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '15px',
        right: '30px',
        bottom: '5%',
        containLabel: true
      },
      legend: {
        padding: [0, 0, 0, 15],
        x: 'left',
        data: ['', 'Emotions Experienced',],
        icon: 'circle'
      },
      xAxis: {
        type: 'category',
        data: data.title_arr,
      },
      yAxis: [{ max: this.BarChartReport[0].value }, {}],
      series: [
        {
          name: '',
          type: 'bar',
          yAxisIndex: 0,
          tooltip: {
            show: false
          },
          data: this.BarChartReport
        },
        {
          name: 'Emotions Experienced',
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#000000',
            width: 2,
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: '#000000',
            color: 'black'
          },
          tension: 2,
          label: {
            show: true,
            position: 'top',
            textStyle: {
              fontSize: 15,
              color: '#000000'
            }
          },
          yAxisIndex: 0,
          tooltip: {
            show: true
          },
          data: data.count_arr
        }
      ]
    }
  }

  public downloadAsPDFs(reporttype) {
    let screenSize = window.innerWidth
    let chartDiv;
    let pdfname;
    if (reporttype == 1) {
      chartDiv = <HTMLImageElement>document.querySelector('#report1');
      pdfname = "Emotions Experienced Between Two Dates"
    }
    let divHeight = chartDiv.clientHeight;
    let divWidth = chartDiv.clientWidth;
    let totalHeight = divHeight + 200;
    let PdfHight;
    if (screenSize < 1600) {
      PdfHight = totalHeight
    } else {
      PdfHight = 500;
    }

    html2canvas(chartDiv, {
      width: divWidth, height: totalHeight,
      onclone: function (clonedDoc) {
        if (screenSize < 1600) {
          PdfHight = totalHeight > 600 ? (totalHeight) : 600;
        } else {
          PdfHight = totalHeight > 600 ? (totalHeight) : 600;
        }
        PdfHight = totalHeight > 870 ? (totalHeight) : 870;
      }, allowTaint: true
    }).then((canvas) => {
      this.imgUrlData = canvas.toDataURL('image/png');

      if (this.observationDetails?.graph_url == '') {
        this.getUrl();
      }
    });
  }

  getGraphDetail() {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    params = params.append('id', this.reviewId);
    this.admin.getData(`${this.baseUrl}/api/v2/observations/observation_graph`, params)
      .subscribe(async (res) => {
        this.graphData = res['data']
        this.tableData = res.data.table_data;

        this.tableData.filter(item => {
          item.sort((a, b) => b.count - a.count)
          item['total'] = 0;
        })
        this.tableData.filter(item => {
          item.filter(data => {
            if (data?.count)
              item['total'] = item['total'] + Number(data?.count);
          })
        })
        this.BarChartReport = [];
        let bigValue = Math.max.apply(Math, this.graphData.count_arr.map(function (o) { return o; }));
        for (let i = 0; i < this.graphData.color_arr.length; i++) {
          this.BarChartReport.push({
            value: bigValue ? (bigValue) : 0,
            itemStyle:
            {
              color: this.graphData.color_arr[i]
            }
          })
        }
        this.showChart(this.graphData);
        setTimeout(() => {
          this.downloadAsPDFs(1);
        }, 5000);
      })
  }
  addItem() {
    this.comments = this.observationForm.get("comments") as FormArray;
		this.comments.push(this.createItem(''));
    this.ref.detectChanges()
  }
  createItem(num): FormGroup {
		return this.formBuilder.group({
			name: [num, [Validators.required,this.noWhitespaceValidator]],
      emotions_detected:['',]
		});
	}
  noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }
  keyvalue(event,i){
    this.notValid=false
    // this.observationForm.value.comments[i].name=event.target.value
    this.checkpastEmotions()
     }
  checkpastEmotions(){
    for (let i = 0; i < this.pastEmotions.length; i++) {
      console.log(this.pastEmotions)
      var pastEmotions = this.pastEmotions[i].array.filter((e) => { return e.checked == true });
      console.log(pastEmotions,this.pastEmotions[i],this.observationForm.value,this.observationForm.valid)
      if(this.pastEmotions[i]?.array?.length==0 || pastEmotions[i]?.length==0){
        this.notValid=true
        return
      }
    }
  }

  activateClearButton() {
    for (let i = 0; i < this.observationDetails?.form?.length; i++) {
      let hasEmotion = this.getLength(this.pastEmotions[i]?.array) > 0
      let hasResponse
      if (this.observationForm.value.comments[i]?.name) {
        hasResponse = true 
      } else hasResponse = false;

      if(hasEmotion || hasResponse) return true;
    }
    return false;
  }

  activateSubmitButton() {
    for (let i = 0; i < this.observationDetails?.form?.length; i++) {
      let hasEmotionEmpty = this.getLength(this.pastEmotions[i]?.array) == 0
      let hasResponseEmpty
      if(this.observationForm.value.comments[i]?.name) {
        hasResponseEmpty = false
      } else {
        hasResponseEmpty = true
      }

      if(hasEmotionEmpty || hasResponseEmpty) return false;
    }

    return this.observationForm.valid;
  }

  utcToLocal(utcDate) {
    if (utcDate) {
      let date = moment.utc(utcDate).local().format('MMM DD, YYYY h:mm A');
      return date;
    } else {
      return utcDate
    }
  }
  async completeProgress(i) {
    this.beliefs_emotion = await this.emotionGet(this.observationForm?.value?.comments[i]?.name)
    this.admin.loader(false);
    let taskListArrays = this.observationForm.get('comments') as FormArray;
taskListArrays.controls[i].patchValue({"emotions_detected":(this.beliefs_emotion?.emotions_detected[0] || 'neutral')})
    this.admin.loader(false);
  }
  emotionGet(val) {
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': 'dec4b4b82fmshb56d8c096117a38p12082fjsnd40ae9cd7a16',
        'X-RapidAPI-Host': 'twinword-emotion-analysis-v1.p.rapidapi.com'
      }
    };
    return this.http.get('https://twinword-emotion-analysis-v1.p.rapidapi.com/analyze/?text='+val, options).toPromise();
  }
}
